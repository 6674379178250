.card-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  padding: 10px;
}

.card {
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 16px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card:hover {
  transform: translateY(-8px);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

.card-title {
  font-size: 1.25rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 12px;
  margin-top: 0;
}

.card-content {
  text-align: left;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.card-content li {
  display: flex;
  align-items: center;
  font-size: 1rem;
  color: #555;
  margin-bottom: 8px;
}

.card-content li::before {
  content: "✔";
  color: #d95152;
  margin-right: 8px;
  font-weight: bold;
}
